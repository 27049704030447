.profile {
  // border: 1px solid blue;
  width: 46%;
  min-width: 330px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 3rem;
  margin-left: 2%;
  margin-right: 2%;
}

.img-overlay-container {
  position: relative;
  width: 60%;
  transition: all 0.3s ease-in-out;

  .overlay {
    display: none;
  }

  .profile-img {
    position: relative;
    display: block;
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
    // margin-bottom: 1rem;
  }
}

.profile .name {
  margin-top: 1rem;
  color: var(--primaryText);
  flex-shrink: 1;
  // font-size: 1.3rem;
  // line-height: 2rem;
  font-weight: 700;
  // make text fit in one line and make it smaller if it doesn't fit
  white-space: nowrap;
  font-size: clamp(1.4rem, 1.5vw, 1.8rem);
}

.profile .cofounder-position-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.5rem;
}

.mail-flex-end {
  flex-grow: 1;
  display: flex;
}

.mail-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: var(--profile-mail-bg);
  border-radius: 10px;
  padding: 5px;
  cursor: pointer;
  text-decoration: none;
  margin-bottom: 0.5rem;

  transition: background-color 0.8s ease-in-out;

  p {
    text-align: center;
    margin: 0 0.5rem;
    color: var(--primaryBackground);
    transition: color 0.8s ease-in-out;
  }
  span {
    margin-top: 0.7rem;
    text-decoration: none;
    color: var(--secondaryBackground);
    transition: color 0.8s ease-in-out;
  }
}

.profile .cofounder {
  color: var(--secondaryText);
  font-size: 1.3rem;
  font-weight: 500;
  font-style: italic;
}

.profile .position {
  color: var(--secondaryText);
  font-size: 1.3rem;
  font-weight: 500;
  font-style: italic;
}

.profile .divider {
  color: var(--secondaryText);
  font-size: 2rem;
  font-weight: 200;
  // margin-bottom: 1rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  transition: color 0.8s ease-in-out;
  -moz-transition: color 0.8s ease-in-out;
}

.profile .description {
  color: var(--secondaryText);
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 1rem;
  text-align: center;
}

.name-container {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  vertical-align: middle;
  justify-content: center;

  .profile-linkedin-icon {
    align-self: center;
  }

  &:hover {
    .profile-linkedin-icon {
      transform: scale(1.5);
      color: var(--linkedin-color-hover) !important;
      transition: all 0.3s ease-in-out !important;
    }
  }
}

.profile-linkedin-icon {
  color: var(--profile-mail-bg);
  font-size: 2rem;
  margin-right: 0.5rem;
  text-rendering: optimizeLegibility;
  transition: all 0.3s ease-in-out !important;

  //   transition: color 0.8s ease-in-out !important;
  //   -moz-transition: color 0.8s ease-in-out !important;
}

@media screen and (min-width: 1200px) {
  .mail-wrapper {
    display: none;
  }

  .img-overlay-container {
    .overlay {
      position: absolute;
      display: block;
      width: 101%;
      height: 101%;
      object-fit: cover;
      border-radius: 50%;
      // margin-bottom: 1rem;
      background-color: #000000;
      opacity: 0;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin-top: -0.5%;
      margin-left: -0.5%;
      transition: opacity 0.3s ease-in-out;
    }

    .overlay-text {
      position: absolute;
      color: var(--profile-overlay-text-color) !important;
      text-align: center;
      text-decoration: none;
      // opacity: 1;
    }

    .overlay-text p {
      font-size: 21px;
      font-style: italic;
      height: 100%;
      padding: 0;
      text-align: center;
      text-shadow: 0.5px 0.5px 1px rgba(0, 0, 0, 0.3);
      color: var(--profile-overlay-text-color) !important;
    }

    .overlay-text p::before {
      content: "";
      float: right;
      height: 100%;
      width: 50%;
      shape-outside: polygon(
        2% 0%,
        100% 0%,
        100% 100%,
        2% 100%,
        50% 94%,
        76.6% 82.7%,
        94% 65.6%,
        100% 50%,
        94% 32.6%,
        76.6% 17.3%,
        50% 6%
      );
      shape-margin: 2%;
    }

    .overlay-text::before {
      content: "";
      float: left;
      height: 100%;
      width: 50%;
      shape-outside: polygon(
        0 0,
        98% 0,
        50% 6%,
        23.4% 17.3%,
        6% 32.6%,
        0 50%,
        6% 65.6%,
        23.4% 82.7%,
        50% 94%,
        98% 100%,
        0 100%
      );
      shape-margin: 2%;
    }

    .overlay-text span {
      left: 0;
      bottom: 25px;
      position: absolute;
      text-align: center;
      text-shadow: 0.5px 0.5px 1px rgba(0, 0, 0, 0.3);
      width: 100%;
    }

    &:hover {
      transform: scale(1.05);
      transition: all 0.3s ease-in-out;
      .overlay {
        opacity: 0.75;
      }
    }
  }
}
