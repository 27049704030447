

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  // height: 100%;
  width: 100%;
}

body {
  width: 100%;
  // height: 100%;
  overflow-x: hidden;
  box-sizing: border-box;
}

/* * { border: 1px solid black; } */

.app {
  background-color: var(--primaryBackground);
  // height: 100%;
  color: var(--primaryText);
  z-index: -1;
  font-family: "Montserrat", sans-serif;
  transition: background-color 0.8s ease-in-out;
  -moz-transition: background-color 0.8s ease-in-out;
}

p {
  font-family: "Montserrat", sans-serif;
  transition: color 0.8s ease-in-out;
  -moz-transition: color 0.8s ease-in-out;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  transition: color 0.8s ease-in-out;
  -moz-transition: color 0.8s ease-in-out;
}

.revologo-top {
  width: 100%;
  max-width: 768px !important;
  padding: 20px;
  object-fit: contain;
  margin: auto;
  display: block;
}
