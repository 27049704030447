#section2 {
  height: auto;
  // background-color: var(--section-solid-background);
  background: var(--section-solid-background-gradient);

  .section-header {
    margin-bottom: 30px;
  }

}

#section2 .section-background {
  // left: -500px;
  height: 100%;
}

#section2-content-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  //   min-height: 80vh;
  justify-content: space-around;
}

#section2-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

#section2-content > * {
  margin: 1em 0;
}

#section2-content p {
  transition: color 0.8s ease-in-out;
  -moz-transition: color 0.8s ease-in-out;
  font-size: 1em;
}

#section2-content .profile-wrapper {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin: 0;
  justify-content: space-around;
}

@media screen and (max-width: 800px) {
  #section2-header {
    font-size: 1.7em;
  }

  #section2-subheader {
    font-size: 1.3em;
  }

  #section2-content p {
    font-size: 1em;
  }
}

@media screen and (min-width: 800px) {
  #section2-header {
    font-size: 2em;
  }

  #section2-subheader {
    font-size: 1.5em;
  }

  #section2-content p {
    font-size: 1.2em;
  }
}

@media screen and (min-width: 1500px) {
  #section2-header {
    font-size: 2.5em;
  }

  #section2-subheader {
    font-size: 2em;
  }

  #section2-content p {
    font-size: 1.6em;
  }
}
