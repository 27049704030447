@import "../../colors.scss";
@import "../../vars.scss";

.navbar * {
  //   border: 1px solid red;
}

.navbar {
  width: 100%;
  height: var(--navbar-height);
  box-sizing: border-box;

  display: flex;
  top: 0;
  align-items: center;
  justify-content: space-between;
  padding: 0 var(--navbar-padding);
  z-index: 1000;

  position: sticky;
  // position: relative;

  background-color: var(--navbar-background);
  transition: background-color 0.8s ease-in-out;
  -moz-transition: background-color 0.8s ease-in-out;
  
}

// .navbar.bg-alpha-99,
// .navbar.bg-alpha-100 {
//   transition-property: background-color;
//   transition-duration: 0.8s;
//   transition-timing-function: ease-in-out;
// }
.navbar.bg-alpha-100 {
      background-color: rgba(var(--navbar-background), 1.0);
    }

.navbar li {
  // width: calc((100% - 2 * var(--navbar-height) - 2 * var(--navbar-padding)) / 7);
  // width: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  //   width: 110px;
  height: 100%;
}

.revo-triangle-container {
  width: var(--navbar-height);
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-right: var(--navbar-padding);
  // padding: 10px;
}

.nav-toggle-container {
  width: var(--navbar-height);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: var(--navbar-padding);
}

.revo-triangle {
  width: calc(var(--navbar-height) * 0.6);
  height: calc(var(--navbar-height) * 0.6);
}

.revologo-menu {
  // width: 100%;
  height: calc(var(--navbar-height) * 0.8);
  max-height: calc(var(--navbar-height) * 0.8);
  max-width: 100%;
  // max-width: 110px;
  // display: inherit;
  object-fit: contain;
  margin-top: calc((var(--navbar-height) - var(--navbar-height) * 0.8) / 2);
  margin-bottom: calc((var(--navbar-height) - var(--navbar-height) * 0.8) / 2);
}

.menu-icon {
  cursor: pointer;

  width: 24px;
  height: 24px;

  display: none;

  fill: var(--secondaryText);
  transition: fill 0.8s ease-in-out;
  -moz-transition: fill 0.8s ease-in-out;
}

.nav-list {
  width: 100%;
  max-width: var(--max-content-width);
  margin: 0 auto;
  height: 100%;
  box-sizing: border-box;

  display: flex;
  justify-content: space-between;

  list-style-type: none;
}

.nav-link {
  cursor: pointer;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  text-decoration: none;
  color: var(--primaryText);
  font-size: 12pt;
  font-family: "Montserrat", sans-serif;

  transition: 0.8s ease-in-out;
  -moz-transition: 0.8s ease-in-out;

}

.nav-link.active {
  background-color: var(--subtleHighlight);
}

.nav-link:hover {
  background-color: var(--subtleHighlight2);
  //   font-weight: 600;
  transition: 0.15s ease-in-out;
  -moz-transition: 0.15s ease-in-out;
  //   font-size: 20pt;
}

.nav-link > * {
  margin: 0px 2px;
}

.nav-icon {
  width: 24px;
  height: 24px;
  fill: var(--revopink);

  transition: all 0.8s ease-in-out;
  -moz-transition: all 0.8s ease-in-out;
}

.hidden-above-md {
  display: none;
}

.hidden-below-md {
  // display: block;
}

/* Responsive Styling */
@media (max-width: 900px) {
  .hidden-above-md {
    display: inline-block;
  }

  .hidden-below-md {
    display: none;
  }

  .menu-icon {
    display: block;
  }

  .nav-list {
    display: flex;
    width: 50%;
    // left: -100%;
    text-align: left;
    position: absolute;
    top: var(--navbar-height);
    left: 0;
    flex-direction: column;
  }

  .nav-list.active {
    display: flex;
    width: 50%;
  }

  .nav-list li {
    position: absolute;
    width: 100%;
    left: -100%;
    transition: left 0.8s ease-in-out;
    -moz-transition: left 0.8s ease-in-out;
    background-color: var(--navbar-background__hex);

    @for $i from 1 through 8 {
      &:nth-child(#{$i}) {
        top: calc(var(--navbar-height) * #{$i} - var(--navbar-height) * 2);
        transition-delay: 0.1s + 0.1s * $i;
        -moz-transition-delay: 0.1s + 0.1s * $i;
      }
    }
  }

  .nav-list.active li {
    position: absolute;
    width: 100%;
    left: 0;
    transition: left 0.8s ease-in-out;
    -moz-transition: left 0.8s ease-in-out;

    @for $i from 1 through 8 {
      &:nth-child(#{$i}) {
        top: calc(var(--navbar-height) * #{$i} - var(--navbar-height) * 2);
        transition-delay: 0.1s + 0.1s * $i;
        -moz-transition-delay: 0.1s + 0.1s * $i;
        background-color: var(--navbar-background__hex);
      }
    }
  }

  .nav-link {
    padding: 10px 0px;
    width: 100%;
  }
}
