.section {
  display: flex;
  position: relative;
  width: 100%;
  // min-height: 100vh;
  // padding-top: calc(0px + var(--navbar-height));
  // padding-bottom: calc(0px + var(--navbar-height));
  padding: 20px 0px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  // border: 1px solid yellow;
}

.section-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  z-index: 0;

}

.section-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--section-overlay-color);
  z-index: 0;
  transition: background 0.8s ease-in-out;
  -moz-transition: background 0.8s ease-in-out;

  &:not(.video) {
    backdrop-filter: blur(10px);
  }

}

.section-content {
  display: flex;
  position: relative;
  z-index: 1;
  width: 80%;
  height: 100%;
  max-width: var(--max-content-width);
  // border: 1px solid red;
  justify-content: center;
}

.section-header {
  color: var(--header-color);
  font-size: 3rem;
  text-align: center;
  font-weight: 700;
}

.section-subheader {
  color: var(--sub-header-color);
  font-size: 2rem;
  text-align: center;
  font-weight: 600;
  transition: color 0.8s ease-in-out;
  -moz-transition: color 0.8s ease-in-out;
}

.section-subsubheader {
  color: var(--sub-sub-header-color);
  font-size: 1.5rem;
  font-style: italic;
  font-weight: 500;
}

.section-background.video {
  // min-width: 100vw;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  position: absolute;

  left: 50%;
  transform: translateX(-50%);
}

p {
  font-size: 1.5rem;
  font-weight: 400;
  color: var(--primaryText);

  &.small {
    font-size: 1.2rem !important;
    font-weight: 400;
    color: var(--primaryText);
  }
}

.quote-italic {
  font-style: italic;
  font-weight: 300;
}

@media screen and (max-width: 1300px) {
  .section-header {
    font-size: 2rem;
  }

  .section-subheader {
    font-size: 1.6rem;
  }

  .section-subsubheader {
    font-size: 1.2rem;
  }

  p {
    font-size: 1.2rem;

    &.small {
      font-size: 1rem;
      font-weight: 400;
      color: var(--primaryText);
    }
  }
}

@media screen and (max-width: 900px) {
  .section-header {
    font-size: 1.5rem;
  }

  .section-subheader {
    font-size: 1.2rem;
  }

  .section-subsubheader {
    font-size: 1rem;
  }

  p {
    font-size: 1rem;
    &.small {
      font-size: 0.9rem;
      font-weight: 400;
      color: var(--primaryText);
    }
  }

  .section-content {
    width: 95%;
  }
}
