.contact-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  h1 {
    font-size: 3rem;
    font-weight: 400;
    margin-bottom: 1rem;
  }
}

.contact {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-evenly;
  width: 100%;
  //   border: 1px solid red;
}

.contact-item {
  display: flex;
  flex-direction: column;
  //   border: 1px solid blue;
  width: 300px;

  align-items: center;
  justify-content: flex-start;

  margin-bottom: 3rem;

  .contact-item-content {
    display: flex;
    flex-direction: column;
  }

  i {
    color: var(--revopink);
    font-size: 5rem;
    margin-bottom: 1rem;
    transition: all 0.8s ease-in-out;
    -moz-transition: all 0.8s ease-in-out;

    &:hover {
      transition: all 0.8s ease-in-out;
      -moz-transition: all 0.8s ease-in-out;
      transform: rotateY(180deg);
    }
  }

  .contact-item-header {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
    transition: all 0.8s ease-in-out;
    -moz-transition: all 0.8s ease-in-out;
  }

  p {
    text-align: center;
    white-space: pre;

    a {
      color: var(--contact-link-color);
      text-decoration: none;
      // margin-top: 0.5rem;
      // margin-bottom: 1rem;
      transition: color 0.8s ease-in-out;
      -moz-transition: color 0.8s ease-in-out;
    }
  }
}

#phone-time-reminder {
  font-size: 0.8rem;
  font-style: italic;
}
