// css gradient tool: https://cssgradient.io/

$themes: (
  "light": (
    "primaryBackground": #ececec,
    "secondaryBackground": #bacfd7,
    "primaryText": #1a1a1a,
    "secondaryText": #274157,
    "header-color": #030b38,
    "sub-header-color": #0b154e,
    "sub-sub-header-color": #17267c,
    "highlightText": #4fa5bd,
    "subtleHighlight": #97c5ef,
    "subtleHighlight2": #7ab8f2,
    "navbar-background__rgb": "184, 214, 242",
    "navbar-background__hex": #b8d6f2,
    "section-overlay-color": rgba(189, 189, 189, 0.5),
    "section-solid-background": rgb(2, 0, 36),
    "section-solid-background-gradient":
      linear-gradient(
        192deg,
        rgba(2, 0, 36, 1) 0%,
        rgba(9, 9, 121, 1) 35%,
        rgba(0, 212, 255, 1) 100%
      ),
    "story-title-bg": #17415466,
    "story-title-color": #fdfdfd,
    "story-date-bg": #ffffff30,
    "story-date-color": #1e1e1e,
    "bobble-inner": #dc4bbd,
    "bobble-outer": #ffffff,
    "infoCardTextColor": #f5f5f5,
    "navbar-icon-color": #1e1e1e,
    "contact-link-color": #2a799e,
    "story-link-color": #253768,
    "profile-mail-bg": #0000007a,
    "bitbybit-color": #ea5e80,
    "profile-overlay-text-color": #fdfdfdcc,
    "linkedin-color-hover": #000000,
  ),
  "dark": (
    "primaryBackground": #2e2c2c,
    "secondaryBackground": #374348,
    "primaryText": #e1e1e1,
    "secondaryText": #b8c7ff,
    "highlightText": #8dcfe1,
    "subtleHighlight": #41464b,
    "subtleHighlight2": #666c72,
    "navbar-background__rgb": "39, 45, 51",
    "navbar-background__hex": #272d33,
    "section-overlay-color": rgba(0, 0, 0, 0.5),
    "section-solid-background": rgb(2, 0, 36),
    "section-solid-background-gradient":
      linear-gradient(
        192deg,
        rgba(2, 0, 36, 1) 0%,
        rgba(9, 9, 121, 1) 35%,
        rgba(0, 212, 255, 1) 100%
      ),
    "story-title-bg": #6ec8f247,
    "story-title-color": #fdfdfd,
    "story-date-bg": #ffffff30,
    "story-date-color": #fdfdfd,
    "bobble-inner": #4fa5bd,
    "bobble-outer": #bde1f1,
    "infoCardTextColor": #f5f5f5,
    "navbar-icon-color": #1e1e1e,
    "contact-link-color": #6ec8f2,
    "story-link-color": #58b0e3,
    "profile-mail-bg": #ffffff71,
    "bitbybit-color": #6ec8f2,
    "profile-overlay-text-color": #fdfdfdcc,
    "linkedin-color-hover": #0077b5,
  ),
);

@each $theme-name, $properties in $themes {
  .app[data-theme="#{$theme-name}"] {
    --revoblue: #6ec8f2;
    --revopink: #ea5e80;

    // define a css variable for each property
    @each $name, $property in $properties {
      --#{$name}: #{$property};

      @if ($name == "navbar-background__rgb") {
        --navbar-background: #{$property};
      }
    }
  }
}
