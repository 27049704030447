// $optionDefaultColours: #ed5565, #fc6e51, #ffce54, #2ecc71, #5d9cec, #ac92ec;
$optionDefaultColours: #ed5565, #fcb851, #dff13c;

.infocard-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  transition: 0.25s;
  -moz-transition: 0.25s;

  .options {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    overflow: hidden;
    min-width: 600px;
    max-width: 1600px;
    width: calc(100% - 100px);
    height: 800px;
    max-height: 80vh;

    .option {
      position: relative;
      overflow: hidden;
      min-width: 80px;
      margin: 10px;
      background-size: cover;
      background-position: center;
      cursor: pointer;

      @for $i from 1 through length($optionDefaultColours) {
        &:nth-child(#{$i}) {
          --defaultBackground: #{nth($optionDefaultColours, $i)};
        }
      }

      transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
      -moz-transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);

      &.active {
        flex-grow: 10000;
        transform: scale(1);
        max-width: 100vw;
        margin: 0px;
        border-radius: 60px;
        background-size: cover;

        .shadow {
          background: rgb(0, 0, 0);
          background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 100%);
        }

        .label {
          bottom: 20px;
          left: 20px;
          .info > div {
            left: 0px;
            opacity: 1;
          }
        }

        .desc {
          top: 0;
          margin: 40px 40px;
          margin-bottom: 0;

          > p {
            left: 0px;
            opacity: 1;
          }
        }
      }

      &:not(.active) {
        flex-grow: 1;
        border-radius: 50px;

        .shadow {
          bottom: -40px;
          background: rgb(0, 0, 0);
          background: linear-gradient(0deg, rgba(0, 0, 0, 1) 30%, rgba(255, 255, 255, 0) 100%);
        }

        .label {
          bottom: 10px;
          left: 10px;

          .info > div {
            left: 20px;
            opacity: 0;
          }
        }

        .desc {
          top: 20px;
          left: 20px;

          > p {
            left: 20px;
            opacity: 0;
          }
        }
      }

      .shadow {
        position: absolute;
        bottom: 0px;
        left: 0px;
        right: 0px;
        height: 120px;

        transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
        -moz-transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
      }

      .shadow2 {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        height: 250px;
        transform: rotate(180deg);

        transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
        -moz-transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
      }

      .label {
        display: flex;
        position: absolute;
        right: 0px;
        height: 60px;

        transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
        -moz-transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);

        .icon {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          min-width: 60px;
          max-width: 60px;
          height: 60px;
          border-radius: 100%;
          background-color: white;
          color: var(--defaultBackground);
          font-size: 1.5rem;

          transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95) !important;
          -moz-transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95) !important;
        }

        .info {
          display: flex;
          flex-direction: column;
          justify-content: center;
          height: auto;
          width: 90%;
          margin-left: 10px;
          color: white;
          white-space: pre;

          > div {
            position: relative;
            transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95), opacity 0.5s ease-out;
            -moz-transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95), opacity 0.5s ease-out;
          }

          .main {
            font-weight: bold;
            font-size: 1.8rem;
            transition-delay: 0.5s;
            -moz-transition-delay: 0.5s;
          }

          .sub {
            transition-delay: 0.5s;
            -moz-transition-delay: 0.5s;
            font-size: 1.2rem;
          }
        }
      }

      .desc {
        display: flex;
        flex-direction: column;
        position: absolute;
        right: 0px;
        margin-bottom: 20px;
        height: 40px;

        transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);

        .icon {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          min-width: 40px;
          max-width: 40px;
          height: 40px;
          border-radius: 100%;
          background-color: white;
          color: var(--defaultBackground);
        }
      }
    }
  }

  p {
    color: var(--infoCardTextColor);
  }
}

@media screen and (max-width: 1450px) {
  .infocard-wrapper {
    .option {
      min-width: 60px !important;

      .label {
        height: 40px !important;

        .icon {
          min-width: 40px !important;
          max-width: 40px !important;
          height: 40px !important;
          font-size: 1.2rem !important;
          transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95) !important;
        }
      }

      &.active {
        border-radius: 30px !important;

        .label {
          .info {
            .main {
              font-size: 1.3rem !important;
            }

            .sub {
              font-size: 1rem !important;
            }
          }
        }
        .desc {
          margin: 20px 20px !important;
          
          p {
            font-size: 1.2rem !important;
          }
        }
      }
      &:not(.active) {
        border-radius: 30px !important;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .infocard-wrapper {
    display: none;
  }
}
