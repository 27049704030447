#footer {
  --bg-color: #252e42;

  height: 70px;
  width: 100%;
  // max-width: 1200px;
  margin: 0 auto;
  background-color: var(--bg-color);
}

.footer-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  vertical-align: middle;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  height: 100%;
  padding: 0 1rem;
}

.impressum-text-wrapper,
.copyright-text-wrapper {
  display: flex;
  vertical-align: middle;
}

.impressum-text,
.copyright-text {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: auto;
  color: #e8f0f4;
  font-size: 1rem;
}

.impressum-text a,
.copyright-text a {
  color: #e8f0f4;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
}

.impressum-text a:hover .copyright-text a:hover {
  text-decoration: underline;
  transition: all 0.8s ease-in-out;
  -moz-transition: all 0.8s ease-in-out;
}

.social-icons {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  vertical-align: middle;
}
.social-icons li {
  display: inline-block;
  margin: auto 5px;
  // margin-bottom: 4px;
}
.social-icons li.title {
  margin-right: 15px;
  text-transform: uppercase;
  color: #96a2b2;
  font-weight: 700;
  font-size: 13px;
}
.social-icons a {
  background-color: var(--bg-color);
  color: #818a91;
  font-size: 16px;
  display: inline-block;
  line-height: 44px;
  width: 44px;
  height: 44px;
  text-align: center;
  // margin-right: 8px;
  margin: auto;
  border-radius: 100%;
  transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
}
.social-icons a:active,
.social-icons a:focus,
.social-icons a:hover {
  color: #fff;
  background-color: #29aafe;
}
.social-icons.size-sm a {
  line-height: 34px;
  height: 34px;
  width: 34px;
  font-size: 14px;
}
.social-icons a.facebook:hover {
  background-color: #3b5998;
}
.social-icons a.twitter:hover {
  background-color: #00aced;
}
.social-icons a.linkedin:hover {
  background-color: #007bb6;
}
.social-icons a.dribbble:hover {
  background-color: #ea4c89;
}
@media (max-width: 767px) {
  .social-icons li.title {
    display: block;
    margin-right: 0;
    font-weight: 600;
  }
}
