#section3 {
  //   min-height:
  // height: calc(900px + 2 * (20px + var(--navbar-height)));
  height: auto;
}

#section3 .section-background {
  height: 100%;
  background-attachment: fixed; /* Parallax Effect for DIV 3 */
}

#section3-content-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  //   min-height: 80vh;
  justify-content: space-around;
}

#section3-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

#section3-content > * {
  margin: 1em 0;
}


#problems-wrapper .problem {
  margin: 1rem 0;
}
