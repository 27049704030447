.linkedin-post {
  background-color: white;
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.08);
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 12px;
  padding-bottom: 8px;
  width: 100%;
  max-width: 700px;
  margin: 20px 0;
  cursor: pointer;

  &.hidden {
    display: none;
  }
}

.linkedin-post-header-left-image {
  height: 3.5rem;
}

.linkedin-post-header-left-image img {
  height: 100%;
}

.display-linebreak {
  white-space: pre-line;
}

.linkedin-post-header {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.linkedin-post-header-left {
  width: 80%;
  display: flex;
  flex-direction: row;
}

.linkedin-post-header-left-text {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  // vertical-align: top;
  margin-left: 5px;
}

.linkedin-post-header-left-text-name {
  font-size: 1em;
  font-weight: 600;
  color: rgb(50, 50, 50);
}

.linkedin-post-header-left-text-follower {
  font-size: 0.8em;
  color: #6d6d6d;
}

.linkedin-post-header-left-text-time {
  font-size: 0.8em;
  color: #6d6d6d;
}

.linkedin-post-header-right {
  width: 20%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.linkedin-button {
  background-color: var(--bg-color);
  color: #818a91;
  font-size: 16px;
  display: inline-block;
  line-height: 44px;
  width: 44px;
  height: 44px;
  text-align: center;
  // margin-right: 8px;
  margin: auto 0;
  border-radius: 100%;
  transition: all 0.2s linear;
  --moz-transition: all 0.2s linear;
}

.linkedin-button:hover {
  background-color: #007bb6;
}

.linkedin-post-body-text {
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 0.8);
  font-size: 0.75em;
}

.hidden {
  display: none;
}

.linkedin-post-body-img-container {
  list-style: none;
  height: 360px;
  overflow: hidden;
  display: grid;
  gap: 1px;
  grid-template-rows: 60% 40%;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  margin-left: -16px;
  width: calc(100% + 32px);
  padding-left: 0px;
  overflow: hidden;
}

.linkedin-post-body-img-container {
  li {
    // Default styles for all li elements
    // ...
    display: none;

    /////////////////////////// 1 Element ///////////////////////////////////
    // Style for the first li when there are exactly 3 li elements
    &:nth-child(1):nth-last-child(1) {
      display: block;
      grid-row-start: 1;
      grid-row-end: -1;
      grid-column-start: 1;
      grid-column-end: -1;
    }

    /////////////////////////// 2 Elements ///////////////////////////////////
    // Style for the first li when there are exactly 3 li elements
    &:nth-child(1):nth-last-child(2),
    &:nth-child(2):nth-last-child(1) {
      display: block;

      grid-row-start: 1;
      grid-row-end: -1;
      grid-column-start: span 3;
      grid-column-end: span 3;
    }
    /////////////////////////// 3 Elements ///////////////////////////////////
    // Style for the first li when there are exactly 3 li elements
    &:nth-child(1):nth-last-child(3) {
      display: block;

      grid-column-start: 1;
      grid-column-end: -1;
    }

    // Style for the last three li when there are exactly 3 li elements
    &:nth-child(2):nth-last-child(2),
    &:nth-child(3):nth-last-child(1) {
      display: block;

      grid-column-start: span 3;
      grid-column-end: span 3;
    }

    /////////////////////////// 4 Elements ///////////////////////////////////
    // Style for the first li when there are exactly 4 li elements
    &:nth-child(1):nth-last-child(4) {
      display: block;

      grid-column-start: 1;
      grid-column-end: -1;
    }

    // Style for the last three li when there are exactly 4 li elements
    &:nth-child(2):nth-last-child(3),
    &:nth-child(3):nth-last-child(2),
    &:nth-child(4):nth-last-child(1) {
      display: block;

      grid-column-start: span 2;
      grid-column-end: span 2;
    }

    /////////////////////////// 5 Elements ///////////////////////////////////

    // Style for the first li when there are 5 li elements
    &:nth-child(1):nth-last-child(5),
    &:nth-child(2):nth-last-child(4) {
      display: block;

      grid-column-start: span 3;
      grid-column-end: span 3;
    }

    // Style for the last three li when there are 5 li elements
    &:nth-child(3):nth-last-child(3),
    &:nth-child(4):nth-last-child(2),
    &:nth-child(5):nth-last-child(1) {
      display: block;

      grid-column-start: span 2;
      grid-column-end: span 2;
    }

    // Style for the first two li when there are more than 5 li elements
    &:nth-child(-n + 2):not(:nth-last-child(-n + 4)) {
      display: block;

      grid-column-start: span 3;
      grid-column-end: span 3;
    }

    // Style for the third to fifth li when there are more than 5 li elements
    &:nth-child(n + 3):nth-child(-n + 5):nth-last-child(n + 2) {
      // Your styles for the third to fifth li
      // ...

      display: block;

      grid-column-start: span 2;
      grid-column-end: span 2;
    }

    &:nth-child(5):nth-last-child(n + 2) {
      img::after {
        content: "Test";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        // width: 100%;
        // height: 100%;

        background: rgba(0, 0, 0, 0.5);
      }
      // &::after {
      //   content: "";
      //   position: absolute;
      //   top: 0;
      //   right: 0;
      //   bottom: 0;
      //   left: 0;
      //   // width: 100%;
      //   // height: 100%;

      //   background: rgba(0, 0, 0, 0.5);
      // }
    }
  }
}

.linkedin-post-body-img-container img {
  width: 100%;
  height: 100%;
  max-height: 400px;
  object-fit: cover;
  object-position: center;
}
