@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700;800;900&display=swap');

.orbitron {
  font-family: 'Orbitron', sans-serif!important;
}

.revoclaim {
  font-size: 3rem;
}



#section1 {
  //   min-height:
  // height: calc(max(100vh, 480px + var(--navbar-height)));
  height: auto;
  min-height: 100vh;
  
  .bitbybit {
    color: var(--bitbybit-color);
    font-size: 3rem;
    transition: color 0.8s ease-in-out;
    -moz-transition: color 0.8s ease-in-out;
  }

  .section-subheader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 4rem;
    white-space: pre;

    &.claim {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      > * {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;

        > * {
          margin: 0.5rem;
        }
      }
    }

    .section-subheader-left {
      margin-right: 0.5rem;
    }

    .section-subheader-right {
      margin-left: 0.5rem;
    }

  }

  .section-background {
    left: -500px;
    height: 100%;
  }

}

#section1-content-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

#section1-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  .wrapper {
    display: flex;
    flex-direction: row;

    .left {
      display: flex;
      flex-direction: column;
      width: 60%;
      justify-content: space-around;
      margin: 0 2rem;

      >p {
        margin: 1.5rem 0;
      }
    }

    .right {
      display: flex;
      width: 40%;
      align-items: center;
      justify-content: center;
      margin: 0 2rem;

      img {
        width: 100%;
      }
    }

  }
}


.emph {
  font-weight: 700;
  
  &.blue {
    color: var(--revoblue);
  }
  // color:var(--revoblue);

  &.pink {
    color:  var(--revopink);
  }
}

#fundedby-header {
  margin-top: 200px;
  margin-bottom: 20px;
}

@media screen and (max-width: 1100px) {
  
  .revoclaim {
    font-size: 2rem;
  }

  #section1 .bitbybit {
    font-size: 2rem;
  }

  #section1 {
    .section-subheader {
      margin-bottom: 2rem;
    }
  }

  #section1-content {
    .wrapper {
      display: flex;
      flex-direction: column-reverse;

      .left {
        width: 100%;
        margin: 1rem 0;

      }
  
      .right {
        width: 100%;
        margin: 1rem 0;

      }
  }
  }
  
  #fundedby-header {
    margin-top: 2rem;
    margin-bottom: 0.5rem;
  }
}

@media screen and (max-width: 800px) {
  .revoclaim {
    font-size: 1.5rem;
  }

  #section1 .bitbybit {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 620px) {
  #section1 {

  .section-subheader {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .section-subheader.claim {
    & > * {
      // wrap
      flex-wrap: wrap;
    }
  }

}
}