#section5 {
  //   min-height:
  // height: calc(max(100vh, 480px + var(--navbar-height)));
  height: auto;

  .section-header {
    margin-bottom: 30px;
  }
}

// #section5 .section-background {
//   left: -500px;
//   height: 100%;
// }

#section5-content-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  //   min-height: 80vh;
  justify-content: space-around;
}

#section5-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

#section5-content > * {
  margin: 1em 0;
}
