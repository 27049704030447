#section4 {
  //   min-height:
  // height: calc(900px + 2 * (20px + var(--navbar-height)));
  height: auto;
  background: var(--section-solid-background-gradient);
}

// #section4 .section-background {
//   height: 100%;
//   // background-attachment: fixed; /* Parallax Effect for DIV 3 */
// }

#section4-header {
  font-size: 2em;
  font-weight: 600;
  color: var(--highlightText);
  margin-bottom: 1.5;
  width: 100%;
}

#section4-subheader {
  font-size: 2em;
  font-weight: 600;
  color: var(--secondaryText);
  margin-bottom: 1.5;
  width: 100%;
}

#section4-content-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  //   min-height: 80vh;
  justify-content: space-around;
}

#section4-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

#section4-content > * {
  margin: 1em 0;
}

#solutions-wrapper .solution {
  margin: 1rem 0;
}
