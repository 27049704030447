.Marquee {
  //   width: 100%;
  //   position: relative;
  height: 75px;
  max-height: 100px;
  box-sizing: border-box;
  padding: 0.2em;
  color: #fff;
  font-weight: 200;
  display: flex;
  align-items: center;
  overflow: visible;
  animation-play-state: paused;
}

.Marquee-content {
  display: flex;
  height: 100%;
  left: 100%;

  animation: marquee 20s linear infinite running;
}

.paused {
  animation-play-state: paused;
}

.Marquee-tag {
  height: 100%;
  margin: 0 1em;
  padding: 0.1em;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
}

.Marquee-logo {
  height: 100%;
  object-fit: contain;
}

.Marquee-tag:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.scale-60 {
  height: 60% !important;
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translate(-100%);
  }
}
