.story-entry {
  display: flex;
  flex-direction: row;
  width: 45%;
}

.story-wrapper-desktop {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  .divider {
    position: absolute;
    width: 5px;
    left: calc(50% - 2.5px);
    background-color: gray;
  }

  .story-entry {
    position: absolute;

    a {
      color: var(--story-link-color);
    }

    &.left {
      left: 2.5%;
      text-align: right;


      &.reveal.slideInFromLeft {
        animation: 0.5s ease-out 0s 1 slideInFromLeft;
        animation-delay: 1.5s;
      }

      .story-entry-date-title {
        flex-direction: row-reverse;
      }

      .story-entry-description {
        text-align: right;
        padding-right: 15px;
      }

    }

    &.right {
      left: 52.5%;

      &.reveal.slideInFromRight {
        animation: 0.5s ease-out 0s 1 slideInFromRight;
        animation-delay: 1.5s;
      }

      .story-entry-date-title {
        flex-direction: row;
      }

      .story-entry-description {
        text-align: left;
        padding-left: calc(var(--story-entry-bobble-height) / 2);
        padding-left: 15px;
      }
    }
  }

  .story-entry-bobble {
    position: absolute;
    left: calc(50% - var(--story-entry-bobble-height) / 2);
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--story-entry-bobble-height);
    height: var(--story-entry-bobble-height);
    // padding-top: calc(var(--story-entry-bobble-height) / 2);
  }

  .story-entry-bobble-inner {
    width: 60%;
    height: 60%;
    border-radius: 50%;
    position: absolute;
    background-color: var(--bobble-inner);
    transition: background-color 0.8s ease-in-out;
    -moz-transition: background-color 0.8s ease-in-out;
  }

  .story-entry-bobble-outer {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
    background-color: var(--bobble-outer);
    border: 1px solid black;
    transition: background-color 0.8s ease-in-out;
    -moz-transition: background-color 0.8s ease-in-out;
  }
}

.story-wrapper-mobile {
  display: none;

  .story-entry.mobile {
    position: relative;
    width: 90%;
    margin-left: 20px;
    // margin-top: 50px;
    margin-bottom: 50px;

    a {
      color: var(--story-link-color);
    }

    .story-entry-description {
      text-align: left;
      padding-left: calc(var(--story-entry-bobble-height) / 2);
      padding-left: 15px;
    }

    .story-entry-bobble {
      position: absolute;
      // left: calc(0 - 10 - var(--story-entry-bobble-height) / 2);
      left: -25px;
    }

    .mobile-divider {
      position: absolute;
      width: 2%;
      height: 100px;
      // align-self: stretch;
      background-color: rgba(255, 255, 255, 0.553);
      margin-left: 10px;
    }
  }

  .story-entry-bobble {
    position: relative;
    // left: calc(50% - var(--story-entry-bobble-height) / 2);
    // top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--story-entry-bobble-height);
    height: var(--story-entry-bobble-height);
    // padding-top: calc(var(--story-entry-bobble-height) / 2);
  }

  .story-entry-bobble-inner {
    width: 60%;
    height: 60%;
    border-radius: 50%;
    position: absolute;
    background-color: var(--bobble-inner);
    transition: background-color 0.8s ease-in-out;
    -moz-transition: background-color 0.8s ease-in-out;
  }

  .story-entry-bobble-outer {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
    background-color: var(--bobble-outer);
    border: 1px solid black;
    transition: background-color 0.8s ease-in-out;
    -moz-transition: background-color 0.8s ease-in-out;
  }
}

@media screen and (max-width: 560px) {
  .story-wrapper-mobile {
    display: block;
  }

  .story-wrapper-desktop {
    display: none;
  }

  .mobile-wrapper {
    display: flex;
    flex-direction: row;
    padding-top: 20px;
  }
}

.arrow-right {
  width: 0;
  height: 0;
  border-top: calc(var(--story-entry-title-height) / 3) solid transparent;
  border-bottom: calc(var(--story-entry-title-height) / 3) solid transparent;
  border-left: 10px solid var(--story-title-bg);
  transition: border-left 0.8s ease-in-out;
  -moz-transition: border-left 0.8s ease-in-out;
}

.arrow-left {
  width: 0;
  height: 0;
  border-top: calc(var(--story-entry-title-height) / 3) solid transparent;
  border-bottom: calc(var(--story-entry-title-height) / 3) solid transparent;
  border-right: 10px solid var(--story-title-bg);
  transition: border-right 0.8s ease-in-out;
  -moz-transition: border-right 0.8s ease-in-out;
}

.story-entry-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.story-entry-date-title {
  width: 100%;
  display: flex;
  align-items: center;
}

.story-entry-title {
  background-color: var(--story-title-bg);
  transition: background-color 0.8s ease-in-out;
  -moz-transition: background-color 0.8s ease-in-out;
  color: var(--story-title-color);
  padding: 10px 5px;
  // height: var(--story-entry-title-height);
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

.story-entry-date {
  // background-color: var(--story-date-bg);
  color: var(--story-date-color);
  padding: 4px 2px;
  // height: var(--story-entry-date-height);
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.8s ease-in-out;
  -moz-transition: background-color 0.8s ease-in-out;
  margin: 0 10px;

  p {
    font-weight: 700 !important;
    display: inline-block
  }
}

.story-entry-description {
  width: 100%;
  margin-top: 0.3rem;
  transition: color 0.8s ease-in-out;
  -moz-transition: color 0.8s ease-in-out;

  a {
    color: var(--primaryText);
    transition: color 0.2s ease-in-out;
    -moz-transition: color 0.2s ease-in-out;

    &:hover {
      color: var(--revoblue);
      transition: color 0.2s ease-in-out;
      -moz-transition: color 0.2s ease-in-out;
    }
  }
}
