$optionDefaultColours: #ed5565, #fc6e51, #ffce54;

.splide {
  display: none;

  .option {
    height: 500px;
    position: relative;
    overflow: hidden;
    min-width: 60px;
    margin: 10px;
    background-size: cover;
    background-position: center;
    cursor: pointer;

    transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
    -moz-transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);

    @for $i from 1 through length($optionDefaultColours) {
      &:nth-child(#{$i}) {
        --defaultBackground: #{nth($optionDefaultColours, $i)};
      }
    }

    flex-grow: 10000;
    transform: scale(1);
    max-width: 100vw;
    margin: 0px;
    border-radius: 40px;
    background-size: cover;

    .shadow {
      background: rgb(0, 0, 0);
      background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(255, 255, 255, 0) 100%);
    }

    .label {
      bottom: 20px;
      left: 20px;
      .info > div {
        left: 0px;
        opacity: 1;
      }
    }

    .desc {
      top: 0;
      margin: 20px 20px;
      margin-bottom: 0;

      > p {
        left: 0px;
        opacity: 1;
      }
    }

    .shadow {
      position: absolute;
      bottom: 0px;
      left: 0px;
      right: 0px;
      height: 120px;

      transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
      -moz-transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
    }

    .shadow2 {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      height: 300px;
      transform: rotate(180deg);

      transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
      -moz-transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
    }

    .label {
      display: flex;
      position: absolute;
      right: 0px;
      align-items: center;
      justify-content: center;

      transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
      -moz-transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);

      .icon {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        // align-self: flex-start;

        min-width: 40px;
        max-width: 40px;
        height: 40px;
        border-radius: 100%;
        // margin-top: 4px;

        background-color: white;
        color: var(--defaultBackground);
      }

      .info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: auto;
        width: 100%;
        margin-left: 10px;
        margin-right: 10px;
        color: white;
        white-space: normal;

        > div {
          position: relative;
          transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95), opacity 0.5s ease-out;
          -moz-transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95), opacity 0.5s ease-out;
        }

        .main {
          font-weight: bold;
          font-size: 1.2rem;
          transition-delay: 0.5s;
          -moz-transition-delay: 0.5s;
        }

        .sub {
          transition-delay: 0.5s;
          -moz-transition-delay: 0.5s;
          font-size: 1rem;
        }
      }
    }

    .desc {
      display: flex;
      flex-direction: column;
      position: absolute;
      right: 0px;
      margin-bottom: 20px;
      height: 40px;

      transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);
      -moz-transition: 0.5s cubic-bezier(0.05, 0.61, 0.41, 0.95);

      .icon {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        min-width: 40px;
        max-width: 40px;
        height: 40px;
        border-radius: 100%;
        background-color: white;
        color: var(--defaultBackground);
      }
    }

    p {
      color: var(--infoCardTextColor);
    }
  }
}

@media screen and (max-width: 1200px) {
  .splide {
    display: block;

    .option {
      .label {
        bottom: 30px;
      }
    }
  }
}

@media screen and (max-width: 530px) {
  
  .section-subheader {
    margin: 15px 0!important;
  }

}