$vars: (
  "max-content-width": 1600px,
  "navbar-height": 50px,
  "navbar-padding": 10px,
  "navbar-alpha": 1,
  "story-entry-bobble-height": 20px,
  "story-entry-title-height": 2rem,
  "story-entry-date-height": 1.3rem,
);

// define css vars
@each $name, $property in $vars {
  .app {
    --#{$name}: #{$property};
  }
}
