.toggle-label {
  margin: 0 0;
  cursor: pointer;
  // border: 1px solid black;
}

.toggle-container {
  /* for codepen */
  /* transform: scale(1.5); */
  /* box-shadow: 9px 9px 54px #090b0f, -9px -9px 54px #11171f; */
  /**************/
  width: 70px;
  height: 30px;
  background-color: #0d1117;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 5px;
  position: relative;
  transition: background 0.5s;
  -moz-transition: background 0.5s;
  overflow: hidden;
}
.toggle-container .icon svg {
  stroke: #fff;
  stroke-width: 1.5px;
  width: 19px;
}
.toggle-container .toggle-dark-icon {
  margin-left: 12px;
  margin-top: 7px;
  margin-bottom: 4px;
}
.toggle-container .toggle-dark-icon svg {
  transition: transform 0.9s;
  -moz-transition: transform 0.9s;
  transform: translateX(0px);
}
.toggle-container .toggle-light-icon {
  margin-top: 7px;
  margin-bottom: 4px;
}
.toggle-container .toggle-light-icon svg {
  transform-origin: center;
  transform: scale(0.7) rotate(0deg);
  position: relative;
  right: 30px;
  transition: transform 0.9s, right 0.5s;
  -moz-transition: transform 0.9s, right 0.5s;
}
.toggle-container .toggle-circle {
  width: 24px;
  height: 24px;
  position: absolute;
  background-color: #fff;
  border-radius: 50%;
  left: 5%;
  transition: left 0.5s;
  -moz-transition: left 0.5s;
}

.toggle-button-mobile-container {
  display: None;
}

#toggle-check {
  display: none;
}
#toggle-check:checked ~ .toggle-label .toggle-container {
  background: #fd7e14;
}
#toggle-check:checked ~ .toggle-label .toggle-container .toggle-circle {
  left: 62%;
}
#toggle-check:checked ~ .toggle-label .toggle-container .toggle-light-icon svg {
  transform: scale(1) rotate(180deg);
  right: 0px;
}
#toggle-check:checked ~ .toggle-label .toggle-container .toggle-dark-icon svg {
  transform: translateX(30px);
}
